import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { Form, FormItem, Select } from 'formik-antd';
import ToolSearchInput from '@uz/unitz-tool-components/ToolSearchInput';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Button } from '@uz/unitz-components-web/Button';
import styled from 'styled-components';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const CustomFormItem = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const Index = () => {
  return (
    <DIV className="memberFilterMenu">
      <div className="p-6 bg-white500 rounded-lg">
        <FormProvider form={ctx.get('filterForm')}>
          <Form {...layout} className="memberFilterMenu">
            <Row gutter={8}>
              <Col xs={24} lg={22}>
                <Row gutter={gutter}>
                  <Col xs={12} lg={8}>
                    <ToolSearchInput
                      name="search"
                      label={ctx.apply('i18n.t', 'ZoomToolMember.Form.search_label')}
                      placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.search_placeholder')}
                      onClick={ctx.get('filterForm.handleSubmit')}
                    />
                  </Col>
                  <Col xs={12} lg={8}>
                    <CustomFormItem>
                      <FormItem
                        name="status"
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolMember.Form.status_label')}
                          </span>
                        }
                      >
                        <CustomInput>
                          <Select
                            size="medium"
                            name="status"
                            suffixIcon={gstyles.icons({
                              name: 'arrow-down3',
                              size: 24,
                              fill: gstyles.colors.sub,
                              className: '-mt-2 -ml-2',
                            })}
                            onChange={ctx.get('handleChangeSubmit')}
                            placeholder={ctx.apply('i18n.t', 'ZoomToolMember.Form.status_placeholder')}
                          >
                            {_.map(ctx.get('statusOptions'), (item) => (
                              <Select.Option
                                style={{ padding: 16 }}
                                className="border-b border-border text-base"
                                value={_.get(item, 'value', '')}
                                key={_.get(item, 'value', '')}
                              >
                                {_.get(item, 'label', '')}
                              </Select.Option>
                            ))}
                          </Select>
                        </CustomInput>
                      </FormItem>
                    </CustomFormItem>
                  </Col>
                </Row>
              </Col>
              {/* <Col style={{ display: 'flex' }} className="justify-end items-end" span={2}>
                <Button
                  style={{ marginTop: '5px', border: `1px solid ${gstyles.colors.border}` }}
                  size="medium"
                  name="ink"
                  block
                  onClick={ctx.get('filterForm.clearFilter')}
                >
                  <span className="text-brandb500 text-base font-semibold text-center">
                    {ctx.apply('i18n.t', 'ZoomToolMember.Form.clear_all_button')}
                  </span>
                </Button>
              </Col> */}
            </Row>
          </Form>
        </FormProvider>
      </div>
    </DIV>
  );
};

export default displayName(Index);
